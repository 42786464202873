<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceType.modelName')"
        v-model.trim="params.name"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button v-show="multipleSelection.length" type="danger" plain @click="delHandler(true)">{{
        $t('delete')
      }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        ref="deviceTypeTable"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />

        <el-table-column :label="$t('deviceType.modelImg')">
          <div slot-scope="{ row }" class="viewer" v-viewer>
            <img :src="row.imagePath" />
          </div>
        </el-table-column>

        <el-table-column prop="name" :label="$t('deviceList.deviceType')" />
        <el-table-column prop="signatureFileName" :label="$t('deviceType.signFileName')" />
        <el-table-column prop="remark" :label="$t('deviceType.remark')" />

        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button v-if="btnShow && btnShow.edit" type="text" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>
              <el-button
                v-if="btnShow && btnShow.delete"
                type="text"
                @click="delHandler(false, row.id)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-device-type-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddDeviceTypeModal from './components/AddDeviceTypeModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddDeviceTypeModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      visible2: false,
      dialogImageUrl: '',
      currentItem: {},
      currentItem2: {},
      multipleSelection: [],
      tableData: [
        // {
        //   id: 1,
        //   img: 'iconphoto',
        //   name: 'SC3318A'
        // },
        // {
        //   id: 2,
        //   img: 'iconphoto',
        //   name: 'SC3318A'
        // }
      ]
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.deviceManagement.modelList(this.params)

        if (res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    detail(id) {
      this.$router.push(`/deviceManagement/deviceList/deviceListDetail/${id}`)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id)
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },
    delHandler(delMore, id) {
      this.$confirm(
        delMore
          ? this.$t('deviceType.deleteSelectedModel')
          : this.$t('deviceType.deleteSelectedThisModel'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.modelDelete(delMore ? this.multipleSelection : [id])
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.viewer {
  img {
    width: 50px;
    height: 50px;
  }
}
</style>
