<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="640px"
    :title="$t('deviceList.sedinittaRetrieve')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div v-if="errorTip" style="text-align: center;">
      {{ errorTip }}
    </div>
    <div class="tips" v-else>
      <p style="margin-bottom: 10px;">{{ $t('deviceList.retrieveSdTips') }}</p>
      <div class="imgList">
        <div class="imgItem" v-for="(url, index) in imgs">
          <div class="title">{{ $t('step') }}{{ index + 1 }}:</div>
          <div class="imgBox">
            <el-image :src="url" :preview-src-list="imgs" />
          </div>
        </div>
      </div>
      <!-- <el-image style="width: 600px;" :src="tips" :preview-src-list="[tips]" /> -->
      <p style="margin-bottom: 10px;">
        IP<el-button
          size="mini"
          icon="el-icon-document-copy"
          v-if="retrieveInfo.ip || retrieveInfo.innerIp"
          @click="copy(addr)"
          style="margin-left: 10px;"
          >{{ $t('copy') }}</el-button
        >：{{ addr }}
      </p>

      <p>
        {{ $t('password')
        }}<el-button
          size="mini"
          icon="el-icon-document-copy"
          v-if="retrieveInfo.password"
          @click="copy(retrieveInfo.password)"
          style="margin-left: 10px;"
          >{{ $t('copy') }}</el-button
        >：{{ retrieveInfo.password || '' }}
      </p>
    </div>
    <span slot="footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="download">{{ $t('downloadTool') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { decrypt } from '@/lib/crypto.js'
import step1 from '@/assets/img/retrieveSteps/step1.png'
import step2 from '@/assets/img/retrieveSteps/step2.png'
import step3 from '@/assets/img/retrieveSteps/step3.png'
import step4 from '@/assets/img/retrieveSteps/step4.png'
import step5 from '@/assets/img/retrieveSteps/step5.png'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      errorTip: '',
      retrieveInfo: {},
      imgs: [step1, step2, step3, step4, step5]
    }
  },
  computed: {
    addr() {
      return (this.retrieveInfo.ip || this.retrieveInfo.innerIp) + ':' + this.retrieveInfo.port
    }
  },
  created() {
    try {
      let data = decrypt(this.currentItem.result)
      this.retrieveInfo = JSON.parse(data)
    } catch (error) {
      this.errorTip = error
    }
  },
  methods: {
    copy(val = '') {
      this.$utils.copyText(val, this)
    },
    download() {
      let url = '/tightvnc-1.3.10_x86_viewer.zip'
      window.open(url, '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
.imgList {
  margin-bottom: 20px;
  .title {
    margin: 10px 0;
  }
  img {
    max-width: 100%;
  }
}
</style>
