<template>
  <div v-loading="loading">
    <el-dialog
      v-elDragDialog
      width="500px"
      :title="$t('deviceRoom.batchAddIssueEquipment')"
      :close-on-click-modal="false"
      :visible.sync="show"
      class="add-moredevice"
      destroy-on-close
    >
      <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
        <el-form-item :label="$t('deviceModal.downloadTemplate')">
          <div>
            <a @click="downLoad" href="javascript:void(0)" style="color:#074d29">{{
              $t('deviceRoom.batchAddIssueEquipmentTemplate')
            }}</a>
          </div>
        </el-form-item>

        <el-form-item :label="$t('uploadFiles')" prop="file">
          <el-upload
            drag
            :on-change="onchange"
            :show-file-list="true"
            :multiple="false"
            :auto-upload="false"
            accept=".csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref="upload"
            name="file"
            action="null"
          >
            <div @click="$refs.upload.clearFiles()">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{ $t('deviceModal.dropFilesHere') }}，{{ $t('deviceModal.or') }}
                <em>{{ $t('deviceModal.clickUpload') }}</em>
              </div>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="checkFile(form)">{{ $t('save') }}</el-button>
      </span>

      <el-dialog
        v-elDragDialog
        append-to-body
        width="600px"
        :title="$t('deviceModal.importFailedData')"
        :close-on-click-modal="false"
        :visible.sync="failDialog"
        class="add-moredevice"
        destroy-on-close
      >
        <a
          @click="export2"
          href="javascript:void(0)"
          style="color:#074d29;margin-bottom:8px;display: inline-block;"
          >{{ $t('deviceModal.exportRepeatData2') }}</a
        >
        <el-table :max-height="400" :data="tableData" border stripe>
          <el-table-column prop="sn" label="SN" align="center" />
          <el-table-column
            prop="describe"
            :label="$t('deviceModal.failureReason')"
            align="center"
          />
        </el-table>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      failDialog: false,
      loading: false,
      tableData: [],
      form: {
        file: ''
      },
      rules: {
        file: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }]
      }
    }
  },
  methods: {
    async downLoad() {
      this.loading = true
      // try {
      //   const res = await this.$api.deviceManagement.deviceDownload({ type: 1 })

      //   window.open(res.result, '_self')
      // } catch (e) {
      //   console.log(e)
      // } finally {
      //   this.loading = false
      // }
      let columns = [
        { header: this.$t('ecxelHeader.sn'), key: 'sn', wch: 20 },
        { header: this.$t('ecxelHeader.deviceType'), key: 'modelName', wch: 20 },
        { header: this.$t('ecxelHeader.spId'), key: 'spId', wch: 20 },
        { header: this.$t('ecxelHeader.printerId'), key: 'printerId', wch: 20 },
        { header: this.$t('ecxelHeader.scanningEquipmentId'), key: 'scanningEquipmentId', wch: 20 },
        { header: this.$t('ecxelHeader.cameraId'), key: 'cameraId', wch: 20 },
        { header: this.$t('ecxelHeader.electronicScalesId'), key: 'electronicScalesId', wch: 20 },
        { header: this.$t('ecxelHeader.store'), key: 'store', wch: 20 }
      ]
      this.$utils.exportExcel(
        columns,
        [],
        `${this.$t('deviceRoom.batchAddIssueEquipmentTemplate')}`
      )
      this.loading = false
    },
    onchange(file) {
      this.form.file = file.raw
    },
    showFail() {
      this.failDialog = true
    },
    async checkFile(form) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          let params = new FormData()
          params.append('file', this.form.file)
          let repetition = false
          // 先检测是否有重复的
          try {
            let { result } = await this.$api.deviceManagement.checkRepetition(params)
            console.log('res', result)
            if (result && result.failCount > 0) {
              try {
                const h = this.$createElement
                await this.$confirm(
                  h(
                    'div',
                    {
                      style: 'padding-left:46px'
                    },
                    [
                      h('div', this.$t('deviceModal.repeatTip', { nums: result.failCount })),
                      h('br'),
                      h(
                        'a',
                        {
                          style: 'color:#074d29;cursor:pointer',
                          on: { click: () => this.export1(result.deviceResVoList) }
                        },
                        this.$t('deviceModal.exportRepeatData')
                      )
                    ]
                  ),
                  this.$t('tips'),
                  {
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    distinguishCancelAndClose: true,
                    confirmButtonText: this.$t('changeUpload'),
                    cancelButtonText: this.$t('noChange')
                  }
                )
                this.submitForm(this.form, true)
              } catch (e) {
                console.log(e)
                if (e == 'cancel') {
                  this.submitForm(this.form, false)
                }
              }
            } else {
              this.submitForm(this.form, false)
            }
          } catch (e) {
            this.$refs.upload?.clearFiles()
          } finally {
            this.loading = false
          }
        }
      })
    },
    export1(errData) {
      let columns = [
        { header: this.$t('ecxelHeader.sn'), key: 'sn', wch: 20 },
        { header: this.$t('ecxelHeader.deviceType'), key: 'modelName', wch: 20 },
        { header: this.$t('ecxelHeader.spId'), key: 'spId', wch: 20 },
        { header: this.$t('ecxelHeader.printerId'), key: 'printerId', wch: 20 },
        { header: this.$t('ecxelHeader.scanningEquipmentId'), key: 'scanningEquipmentId', wch: 20 },
        { header: this.$t('ecxelHeader.cameraId'), key: 'cameraId', wch: 20 },
        { header: this.$t('ecxelHeader.electronicScalesId'), key: 'electronicScalesId', wch: 20 },
        { header: this.$t('ecxelHeader.store'), key: 'store', wch: 20 }
      ]
      let data = (errData || []).map(item => {
        return {
          ...item.vo
        }
      })
      this.$utils.exportExcel(columns, data, `${this.$t('deviceModal.exportRepeatDataName')}`)
    },
    export2() {
      let columns = [
        { header: this.$t('ecxelHeader.sn'), key: 'sn', wch: 20 },
        { header: this.$t('ecxelHeader.deviceType'), key: 'modelName', wch: 20 },
        { header: this.$t('ecxelHeader.spId'), key: 'spId', wch: 20 },
        { header: this.$t('ecxelHeader.printerId'), key: 'printerId', wch: 20 },
        { header: this.$t('ecxelHeader.scanningEquipmentId'), key: 'scanningEquipmentId', wch: 20 },
        { header: this.$t('ecxelHeader.cameraId'), key: 'cameraId', wch: 20 },
        { header: this.$t('ecxelHeader.electronicScalesId'), key: 'electronicScalesId', wch: 20 },
        { header: this.$t('ecxelHeader.store'), key: 'store', wch: 20 },
        { header: this.$t('deviceModal.failureReason'), key: 'failureReason', wch: 20 }
      ]
      let data = (this.tableData || []).map(item => {
        return {
          ...item.vo,
          failureReason: item.describe
        }
      })
      this.$utils.exportExcel(columns, data, `${this.$t('deviceModal.exportRepeatDataName2')}`)
    },
    submitForm(data, repetition) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          let params = new FormData()
          params.append('file', this.form.file)
          params.append('repetition', repetition)

          try {
            const res = await this.$api.deviceManagement.deviceRoomImport(params)
            const h = this.$createElement

            if (res.result) {
              const result = res.result
              this.tableData = result.deviceResVoList || []

              // 需要后台返回成功和失败数据
              const total = result.total

              const failCounts = result.failCount || 0

              const successCounts = total ? total - failCounts : 0
              let importSuccee = this.$t('deviceModal.importSuccee')
              let deliveryEquipment =
                this.$t('adminHomePage.tower') + this.$t('deviceRoom.deliveryEquipment')
              let dataImportFailed =
                this.$t('deviceModal.strip') + this.$t('deviceModal.dataImportFailed')
              let viewImportFailed = this.$t('deviceModal.viewImportFailed')
              this.$message.success({
                message: h('p', { style: 'padding-right: 26px;line-height:26px; color:#074d29' }, [
                  h('span', null, importSuccee),
                  h('b', { style: 'color: #074d29' }, successCounts),
                  this.$t('adminHomePage.tower') + ', ',
                  this.$t('deviceRoom.fail'),
                  h('b', { style: failCounts ? 'color: red' : '' }, failCounts),
                  this.$t('adminHomePage.tower') + ', ',
                  failCounts
                    ? h(
                        'a',
                        {
                          style: 'color:#074d29;cursor:pointer',
                          on: { click: () => this.showFail() }
                        },
                        viewImportFailed
                      )
                    : ''
                ]),
                duration: 6000,
                showClose: true
              })
              this.$emit('getData')

              if (failCounts === 0 || !repetition) {
                this.show = false
              }
            }
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-moredevice {
  .el-upload-list__item-name {
    cursor: default;
    &:hover,
    &:focus {
      color: inherit !important;
    }
  }
  ::v-deep .el-upload-list__item-status-label {
    display: none !important;
  }
}
</style>
