<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="$t('deviceList.bindingBatchDevice')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-moredevice"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="150px">
      <el-form-item :label="$t('deviceModal.downloadTemplate')">
        <div>
          <a @click="downLoad" href="javascript:void(0)" style="color:#074d29">{{
            $t('deviceList.bindingBatchDeviceTemplate')
          }}</a>
        </div>
      </el-form-item>

      <el-form-item :label="$t('uploadFiles')" prop="file">
        <el-upload
          drag
          :on-change="onchange"
          :show-file-list="true"
          :multiple="false"
          :auto-upload="false"
          accept=".csv, .xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref="upload"
          name="file"
          action="null"
        >
          <div @click="$refs.upload.clearFiles()">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t('deviceModal.dropFilesHere') }}，{{ $t('deviceModal.or') }}
              <em>{{ $t('deviceModal.clickUpload') }}</em>
            </div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>

    <el-dialog
      v-elDragDialog
      append-to-body
      width="600px"
      :title="$t('deviceModal.importFailedData')"
      :close-on-click-modal="false"
      :visible.sync="failDialog"
      class="add-moredevice"
    >
      <el-table :max-height="400" :data="tableData" border stripe>
        <el-table-column prop="sn" label="sn" align="center" />
        <el-table-column prop="describe" :label="$t('deviceModal.failureReason')" align="center" />
      </el-table>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      failDialog: false,
      loading: false,
      tableData: [],
      form: {
        file: ''
      },
      rules: {
        file: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }]
      }
    }
  },
  methods: {
    async downLoad() {
      this.loading = true
      try {
        // const res = await this.$api.deviceManagement.deviceDownload({ type: 0 })

        // window.open(res.result, '_self')
        let columns = [
          { header: this.$t('ecxelHeader2.head1'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head2'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head3'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head4'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head5'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head6'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head7'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head8'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head9'), key: '', wch: 20 },
          { header: this.$t('ecxelHeader2.head10'), key: '', wch: 20 }
        ]
        this.$utils.exportExcel(columns, [], `${this.$t('deviceList.bindingBatchDeviceTemplate')}`)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onchange(file) {
      this.form.file = file.raw
    },
    showFail() {
      this.failDialog = true
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          let params = new FormData()
          params.append('file', this.form.file)

          try {
            const res = await this.$api.deviceManagement.deviceImport(params)
            const h = this.$createElement

            if (res.result) {
              const result = res.result
              this.tableData = result.deviceResVoList || []

              // 需要后台返回成功和失败数据
              const total = result.total

              const failCounts = result.failCount || 0

              const successCounts = total ? total - failCounts : 0
              let importSuccee = this.$t('deviceModal.importSuccee')
              let devicesUnit =
                this.$t('adminHomePage.tower') + this.$t('deviceModal.devices') + ', '
              let dataImportFailed =
                this.$t('deviceModal.strip') + this.$t('deviceModal.dataImportFailed')
              let viewImportFailed = this.$t('deviceModal.viewImportFailed')
              this.$message.success({
                message: h('p', { style: 'padding-right: 26px;line-height:26px; color:#074d29' }, [
                  h('span', null, importSuccee),
                  h('b', { style: 'color: #074d29' }, successCounts),
                  devicesUnit,
                  h(
                    'b',
                    { style: failCounts ? 'color: red' : '' },
                    failCounts > 0 ? failCounts : ''
                  ),
                  failCounts > 0 ? dataImportFailed : '',
                  failCounts
                    ? h(
                        'a',
                        {
                          style: 'color:#074d29;cursor:pointer',
                          on: { click: () => this.showFail() }
                        },
                        viewImportFailed
                      )
                    : ''
                ]),
                duration: 6000,
                showClose: true
              })
              this.$emit('getData')

              if (failCounts === 0) {
                this.show = false
              }
            }
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-moredevice {
  .el-upload-list__item-name {
    cursor: default;
    &:hover,
    &:focus {
      color: inherit !important;
    }
  }
  ::v-deep .el-upload-list__item-status-label {
    display: none !important;
  }
}
</style>
