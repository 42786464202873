<template>
  <page :borderBottom="true">
    <div slot="headerLeft">
      <span
        class="spanItem"
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListDetail/${$route.params.sn}/${$route.params.model}`
          )
        "
        >{{ $t('deviceList.basicDeviceDetails') }}</span
      >
      <span
        class="spanItem"
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListTask/${$route.params.sn}/${$route.params.model}`
          )
        "
        >{{ $t('deviceList.task') }}</span
      >
      <span class="spanItem active">{{ $t('deviceList.logDownload') }}</span>
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <el-button
        size="small"
        type="primary"
        @click="$router.push('/deviceManagement/deviceList/index')"
      >
        {{ $t('backList') }}</el-button
      >
    </div>
    <page>
      <div slot="headerLeft">
        <!-- <el-select clearable v-model="params.type" :placeholder="$t('deviceList.logType')">
          <el-option v-for="item in logTypes" :key="item.id" :label="item.label" :value="item.id" />
        </el-select> -->

        <el-date-picker
          v-model="currentDate"
          type="daterange"
          align="right"
          unlink-panels
          :range-separator="$t('to')"
          :start-placeholder="$t('startDate')"
          :end-placeholder="$t('endDate')"
          value-format="yyyy-MM-dd"
        />

        <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      </div>
      <div slot="headerRight">
        <el-button v-loading="btnLoading" type="primary" @click="getDeviceLogs">{{
          $t('getDeviceLocalLogs')
        }}</el-button>
      </div>

      <div class="table-wrap">
        <el-table :data="tableData" border v-loading="loading" height="100%">
          <el-table-column show-overflow-tooltip :label="$t('deviceList.operationTime')">
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.createTime) }}
            </template>
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            prop="systemVersion"
            :label="$t('deviceList.systemVersion')"
          />

          <el-table-column
            show-overflow-tooltip
            prop="solidVersion"
            :label="$t('deviceList.firmwareVersion')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="cloudPlatformVersion"
            :label="$t('deviceList.cloudPlatformVersion')"
          />
          <el-table-column show-overflow-tooltip prop="type" :label="$t('deviceList.logType')" />
          <el-table-column show-overflow-tooltip prop="size" :label="$t('deviceList.logSize')" />

          <el-table-column :label="$t('operation')">
            <template slot-scope="{ row }">
              <div class="operateRow">
                <el-button type="text" @click="downLoad(row)">{{ $t('download') }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        slot="pagination"
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.currentPage"
        @change="getData"
      />
      <add-service-modal
        @getData="replaceData"
        :currentItem="currentItem"
        :visible.sync="visible"
        v-if="visible"
      />
      <el-dialog
        :title="$t('tips')"
        :visible.sync="loadingDialogVisible"
        width="300px"
        :before-close="loadingDialogClose"
      >
        <div style="display: flex;justify-content: center;margin-bottom: 10px;">
          <i class="el-icon-loading" style="font-size: 50px;"></i>
        </div>
        <div style="text-align: center;padding-bottom: 30px">
          {{ $t('deviceUploadTip') }}
        </div>
      </el-dialog>
    </page>
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import downloadTxt from '@/lib/download.js'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      btnLoading: false,
      visible: false,
      loadingDialogVisible: false,
      currentDate: '',
      // logTypes: [
      //   {
      //     label: 'default',
      //     id: 1
      //   },
      //   {
      //     label: 'events',
      //     id: 2
      //   },
      //   {
      //     label: 'main',
      //     id: 3
      //   },
      //   {
      //     label: 'radio',
      //     id: 4
      //   },
      //   {
      //     label: 'system',
      //     id: 5
      //   }
      // ],
      logTypes: [
        {
          label: this.$t('deviceList.remote'),
          id: 3
        },
        {
          label: this.$t('deviceList.otaUpgrade'),
          id: 4
        },
        {
          label: this.$t('deviceList.appIssued'),
          id: 5
        },
        {
          label: this.$t('deviceList.other'),
          id: 6
        }
      ],
      tableData: []
    }
  },
  created() {},
  watch: {
    currentDate(val) {
      if (val?.length) {
        this.params.createTimeStart = new Date(val[0] + ' 00:00:00').getTime()
        this.params.createTimeEnd = new Date(val[1] + ' 23:59:59').getTime()
      } else {
        this.params.createTimeStart = undefined
        this.params.createTimeEnd = undefined
      }
    }
  },
  methods: {
    async getDeviceLogs() {
      const sn = this.$route.params.sn
      this.btnLoading = true
      try {
        const res = await this.$api.deviceManagement.deviceGet({ sn: sn })
        const data = res.result
        if (data.onlineStatus) {
          this.$alert(this.$t('deviceOfflineTip'), this.$t('tips'), {
            confirmButtonText: this.$t('confirm')
          })
          this.getData()
        } else {
          await this.$api.deviceManagement.deviceSendCommand({
            sn,
            command: 'device:logReport'
          })
          this.loadingDialogVisible = true
          this.timeFlag = setTimeout(() => {
            this.loadingDialogClose()
          }, 5000)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const data = this.params
        data.sn = this.$route.params.sn
        const res = await this.$api.deviceManagement.deviceLogList(data)

        if (res.result) {
          this.tableData = ((res.result && res.result.list) || []).map(item => {
            item.solidVersion = this.$route.params.version === '0' ? '' : this.$route.params.version
            item.size = this.$utils.bytesToSize(item.size)
            return item
          })

          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async downLoad(row) {
      if (!row.logName) {
        this.$message.warning(this.$t('deviceList.noDownload'))
        return
      }
      window.open(row.logName, '_blank')
      // if (row.systemType == 1) {
      //   downloadTxt(row.content, row.logName, 'txt')
      // } else {
      //   this.loading = true
      //   try {
      //     const res = await this.$api.deviceManagement.deviceLogGet(row.id)

      //     if (!res.result.downloadUrl) {
      //       this.$message.warning(this.$t('deviceList.noDownload'))
      //       return
      //     }

      //     window.open(res.result.downloadUrl, '_self')
      //   } catch (e) {
      //     console.log(e)
      //   } finally {
      //     this.loading = false
      //   }
      // }
    },
    loadingDialogClose() {
      clearTimeout(this.timeFlag)
      this.loadingDialogVisible = false
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  padding: 6px 15px;
  // margin-bottom: 5px;
}
</style>
