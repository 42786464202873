<template>
  <page :borderBottom="true">
    <div slot="headerLeft">
      <span
        class="spanItem"
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListDetail/${$route.params.sn}/${$route.params.model}`
          )
        "
        >{{ $t('deviceList.basicDeviceDetails') }}</span
      >
      <span class="spanItem active">{{ $t('deviceList.task') }}</span>
      <span
        class="spanItem"
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListLog/${$route.params.sn}/${
              $route.params.model
            }/${baseInfo.firmwareVersion || 0}`
          )
        "
        >{{ $t('deviceList.logDownload') }}</span
      >
    </div>
    <div slot="headerRight">
      <div slot="headerRight" class="flex" style="align-items: center;">
        <span @click="refresh" :title="$t('refresh')" class="el-icon-refresh"></span>
        <el-button
          size="small"
          type="primary"
          @click="$router.push('/deviceManagement/deviceList/index')"
        >
          {{ $t('backList') }}</el-button
        >
      </div>
    </div>

    <page>
      <!-- <div slot="headerLeft">
        <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      </div> -->

      <div class="table-wrap">
        <el-table :data="tableData" border v-loading="loading" height="100%">
          <el-table-column show-overflow-tooltip prop="taskName" :label="$t('deviceList.taskName')">
            <template slot-scope="{ row }">
              {{ row.taskName == '定时重启' ? $t('deviceList.scheduledRestart') : '-' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip :label="$t('deviceList.createdTime')">
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.createTime) }}
            </template>
          </el-table-column>

          <el-table-column show-overflow-tooltip :label="$t('deviceList.isStatus')">
            <template slot-scope="{ row }">
              <span v-if="row.distributionStatus == 1">{{ $t('deviceList.DisStatus1') }}</span>
              <span v-else-if="row.distributionStatus == 0">{{ $t('deviceList.DisStatus0') }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('operation')">
            <template slot-scope="{ row }">
              <div class="operateRow">
                <el-button
                  type="text"
                  @click="
                    visible = true
                    currentItem = row
                  "
                  >{{ $t('details') }}</el-button
                >
                <el-button type="text" @click="del(row)">{{ $t('delete') }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        slot="pagination"
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.currentPage"
        @change="getData"
      />
      <!-- 定时重启 详情-->
      <ScheduledRestartModal
        :currentItem="currentItem"
        :visible.sync="visible"
        :isDelete="true"
        v-if="visible"
        @getData="replaceData"
      />
    </page>
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import ScheduledRestartModal from '../components/ScheduledRestartModal'
export default {
  mixins: [watchParamsGetData],
  components: { ScheduledRestartModal },
  data() {
    return {
      loading: false,
      visible: false,
      tableData: [],
      baseInfo: {},
      currentItem: null
    }
  },
  created() {
    const row = JSON.parse(sessionStorage.getItem('currentRow'))
    this.baseInfo.firmwareVersion = row.firmwareVersion || ''
  },
  watch: {},
  methods: {
    async refresh(val) {
      this.loading = true
      try {
        await this.$api.deviceManagement.deviceSendCommand({
          sn: this.$route.params.sn,
          command: 'device:logReport'
        })
        this.getData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const data = this.params
        data.sn = this.$route.params.sn
        const res = await this.$api.deviceManagement.getRestStartList(data)

        if (res.result) {
          this.tableData = res.result || []
        } else {
          this.tableData = []
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // 删除任务
    async del(row) {
      try {
        if (!row.sn) return
        await this.$confirm(this.$t('deviceList.confirmTask'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        await this.$api.deviceManagement.delTaskBySn({ sn: row.sn })
        this.$message.success(this.$t('succeeded'))
        this.getData()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  padding: 6px 15px;
  // margin-bottom: 5px;
}
.el-icon-refresh {
  font-size: 20px;
  color: #666;
  font-weight: bold;
  cursor: pointer;
}
</style>
