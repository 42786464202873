<template>
  <page>
    <div slot="headerLeft" style="display:block;width:100%;">
      <div class="flex" style="margin-bottom:15px">
        <el-card shadow="never" class="listItem" v-for="(item, index) in cardLists" :key="index">
          <icon-svg :icon-class="item.icon" />
          <div class="textWrap">
            <p class="label">{{ item.label }}</p>
            <p>
              <span>{{ item.count }}</span>
            </p>
          </div>
        </el-card>
      </div>

      <el-input
        :placeholder="$t('deviceList.sn')"
        style="width:24%"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
      />
      <el-button style="margin-left:10px" type="primary" plain @click="replaceData">{{
        $t('query')
      }}</el-button>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="230"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />

        <el-table-column
          show-overflow-tooltip
          width="160"
          :label="$t('activeDevice.activationTime')"
        >
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.activationTime) }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip width="160" :label="$t('deviceList.lastOnlineTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.lastOnlineTime) }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          show-overflow-tooltip
          width="120"
          prop="totalOnlineTimes"
          :label="$t('deviceList.onlineTimes')"
        /> -->

        <el-table-column
          show-overflow-tooltip
          prop="onlineStatus"
          :label="$t('activeDevice.state')"
        >
          <template slot-scope="{ row }">
            <span :style="{ color: row.onlineStatus === 0 ? '#31ac6d' : '#F56C6C' }">
              {{ row.onlineStatus === 0 ? $t('deviceList.onLine') : $t('deviceList.offLine') }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: {},
      cardLists: [
        {
          icon: 'icondevicelogs',
          label: this.$t('activeDevice.todayNew'),
          count: 0
        },
        {
          icon: 'icondevicelogs',
          label: this.$t('activeDevice.weekNew'),
          count: 0
        },
        {
          icon: 'icondevicelogs',
          label: this.$t('activeDevice.monthNew'),
          count: 0
        },
        {
          icon: 'icondevicelogs',
          label: this.$t('adminHomePage.deviceTotal'),
          count: 0
        }
      ],
      tableData: []
    }
  },
  created() {
    this.getActivationStatistics()
  },
  methods: {
    //获取统计数据
    async getActivationStatistics() {
      this.loading = true
      try {
        const res = await this.$api.deviceManagement.getActivationStatistics()
        if (res.result) {
            this.cardLists[0].count = res.result.increaseToday
            this.cardLists[1].count = res.result.increaseWeek
            this.cardLists[2].count = res.result.increaseMonth
            this.cardLists[3].count = res.result.increaseTotal
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData() {
      this.loading = true
      try {
        const data = this.params

        if (!data.sn) {
          delete data.sn
        }

        const res = await this.$api.deviceManagement.getActivationList(data)

        if (res.result) {
          /*
          this.cardLists[0].count = res.result.increaseToday
          this.cardLists[1].count = res.result.increaseWeek
          this.cardLists[2].count = res.result.increaseMonth
          this.cardLists[3].count = res.result.increaseTotal
          */
          if (res.result.deviceActivationResVoList) {
            const target = res.result.deviceActivationResVoList
            this.tableData = target.list
            this.total = target.total
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.containHeader {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 30px;
  & > div {
    * + * {
      margin-left: 0;
    }
    & + div {
      margin-left: 0;
    }
  }
}

.listItem {
  width: 24%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  height: 85px;
  margin-right: 10px;

  .textWrap {
    margin-left: 10px;
    text-align: center;
    line-height: 26px;
  }

  ::v-deep .el-card__body {
    flex: 1;
    justify-content: space-around;
    display: flex;
  }
  .svg-icon {
    width: 50px;
    height: 50px;
    color: #39c8a8;
  }
}
</style>
