var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',{attrs:{"borderBottom":true}},[_c('div',{attrs:{"slot":"headerLeft"},slot:"headerLeft"},[_c('span',{staticClass:"spanItem",on:{"click":function($event){return _vm.$router.push(
          `/deviceManagement/deviceList/deviceListDetail/${_vm.$route.params.sn}/${_vm.$route.params.model}`
        )}}},[_vm._v(_vm._s(_vm.$t('deviceList.basicDeviceDetails')))]),_c('span',{staticClass:"spanItem active"},[_vm._v(_vm._s(_vm.$t('deviceList.task')))]),_c('span',{staticClass:"spanItem",on:{"click":function($event){return _vm.$router.push(
          `/deviceManagement/deviceList/deviceListLog/${_vm.$route.params.sn}/${
            _vm.$route.params.model
          }/${_vm.baseInfo.firmwareVersion || 0}`
        )}}},[_vm._v(_vm._s(_vm.$t('deviceList.logDownload')))])]),_c('div',{attrs:{"slot":"headerRight"},slot:"headerRight"},[_c('div',{staticClass:"flex",staticStyle:{"align-items":"center"},attrs:{"slot":"headerRight"},slot:"headerRight"},[_c('span',{staticClass:"el-icon-refresh",attrs:{"title":_vm.$t('refresh')},on:{"click":_vm.refresh}}),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push('/deviceManagement/deviceList/index')}}},[_vm._v(" "+_vm._s(_vm.$t('backList')))])],1)]),_c('page',[_c('div',{staticClass:"table-wrap"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"border":"","height":"100%"}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"taskName","label":_vm.$t('deviceList.taskName')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.taskName == '定时重启' ? _vm.$t('deviceList.scheduledRestart') : '-')+" ")]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":_vm.$t('deviceList.createdTime')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$utils.formatTime(row.createTime))+" ")]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":_vm.$t('deviceList.isStatus')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.distributionStatus == 1)?_c('span',[_vm._v(_vm._s(_vm.$t('deviceList.DisStatus1')))]):(row.distributionStatus == 0)?_c('span',[_vm._v(_vm._s(_vm.$t('deviceList.DisStatus0')))]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('operation')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"operateRow"},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.visible = true
                  _vm.currentItem = row}}},[_vm._v(_vm._s(_vm.$t('details')))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v(_vm._s(_vm.$t('delete')))])],1)]}}])})],1)],1),_c('pagination',{attrs:{"slot":"pagination","total":_vm.total,"page-size":_vm.params.pageSize,"page":_vm.params.currentPage},on:{"update:pageSize":function($event){return _vm.$set(_vm.params, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "pageSize", $event)},"update:page":function($event){return _vm.$set(_vm.params, "currentPage", $event)},"change":_vm.getData},slot:"pagination"}),(_vm.visible)?_c('ScheduledRestartModal',{attrs:{"currentItem":_vm.currentItem,"visible":_vm.visible,"isDelete":true},on:{"update:visible":function($event){_vm.visible=$event},"getData":_vm.replaceData}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }